body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #1a202c;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.header-logo {
  width: 50%;
  /* max-width: 500px; */
}

.banner-container {
  width: 100%;
  height: 720px;
  position: absolute;
  top: 150px;
}
.box_header_overly {
  height: 700px;
  width: 100%;
  overflow: hidden;
  position: absolute;
}
.header-h1{
  font-size: 6rem;
  font-family: 'Poppins', sans-serif;
  /* text-transform: uppercase; */
  color: #FFFFFF;
  margin: 0 !important;
  margin-bottom: 12px !important;
}
.header-h1-mobile {
  font-size: 2.8rem;
  text-transform: uppercase;
  color: #212121;
  text-align: center;
  margin: 0 !important;
}
.header-h2 {
  font-weight: '900';
  font-size: 30px;
  line-height: normal;
  color: #FFFFFF;
}
.header-mobile {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #212121;
}
.header-subtitle {
  line-height: 0;
  color: #fff;
}
.hero-cta-container {
  height: 580px;
  display: flex;
  align-items: center;
  color: '#FFFFFF';
  margin-top: 24px;
}

.landing-img-container {
  height: 600px;
  width: 590px;
  display: flex;
  margin-left: 120px;
  justify-content: flex-end;
  align-items: center;
}

.header-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

* {
  border: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.box {
  height: 100%;
  width: 100%;
  background: linear-gradient(#00000000, #000000dd);
  overflow: hidden;
  position: absolute;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.bussiness-dashboard-img {
  width: 100%;
}
p {
  margin-bottom: 0 !important;
  line-height: 1.7;
}
a img {
  border: none;
}
h4 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
a {
  color: #2b2b2b;
  text-decoration: none;
}
a:hover {
  color: #666666;
  text-decoration: none;
}
.nav-bar-icon {
  color: rgb(174, 176, 194);
  font-size: 14px;
}
.map-city-icon {
  color: rgb(174, 176, 194);
  font-size: 12px;
}
.review-icon {
  color: #ffca28;
  font-size: 20px;
}
.navbar-nav > li > a {
  padding-top: 20px !important;
  padding-bottom: 15px;
}
.app-wrapper {
  max-width: 1280px;
  height: 100%;
  width: 90%;
  margin: auto;
}
.container_slider {
  position: relative;
  margin: auto;
}
.card-img-container{
  width:100%;
  height:100%;
  /* min-height: 532px; */
  object-fit: cover;
  overflow: hidden;
}
.product-img-container{
  width:100%;
  height:100%;
  /* min-height: 340px; */
  object-fit: contain;
  /* overflow: hidden; */
}
.buttonBack {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.buttonNext {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.app-logo-header {
  height: 30px;
}
.splash-logo-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
}
.splash-logo {
  width: 60px;
  height: 60px;
  margin-top: 25px;
  font-size: 21px;
  text-align: center;

  -webkit-animation: fadein 0.5s; /* Safari; Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.center {
  display: flex;
}
.sign-up-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  background: rgb(255, 64, 191);
  background: linear-gradient(35deg, rgb(50, 191, 37) 0%, rgb(0, 135, 31) 99%);
}
.freebirdFormviewerViewCenteredContent {
  width: 100% !important;
}
.freebirdFormviewerComponentsQuestionBaseRoot {
  border: none !important;
  margin-bottom: 6px !important;
  padding: 0 !important;
}
.footer {
  flex: 1;
  display: flex;
  justify-content: center;
  min-height: 300px;
  color: #ffffff;
  /* background-color: #212121; */
  background: rgb(46, 50, 79);
  background: linear-gradient(
    8deg,
    rgba(46, 50, 79, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  padding-bottom: 16px;
}
.footer-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.footer li {
  float: left;
}
.footer li a {
  display: block;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  padding: 16px;
  text-decoration: none;
  color: #ffffff;
}
.footer a {
  text-decoration: none;
  color: #ffffff;
}
.footer li a:hover {
  background-color: #ffffff;
}

.join-community-h1 {
  color: #ffffff;
  font-weight: bold;
  font-size: 48px;
  text-transform: uppercase;
  text-shadow: 0px 0px 5px #292929;
}
.mobile-nav-bar {
  position: fixed;
  z-index: 1000;
  height: 48px;
  width: 100%;
  flex-direction: row;
  height: 48px;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #101118;
}
.app-logo {
  margin-top: 15px;
  margin-bottom: 10px;
  height: 70px;
  align-items: center;
  flex-direction: column;
}
.desktop-nav-bar {
  position: fixed;
  z-index: 1000;
  height: 60px;
  width: 100%;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}
.desktop-nav-items-container {
  display: flex;
  margin-top: 8px;
  margin-bottom: 10px;
  height: 35px;
  flex-direction: row;
  align-items: flex-end;
}
.app-logo-desktop {
  margin-top: 15px;
  margin-bottom: 10px;
  height: 70px;
  align-items: center;
  flex-direction: column;
}
.nav-bar-button {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.join-community-cta-container {
  background-image: url('../assets/images/start-buying.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 380px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: '#212121';
}

.download-app-cta-container {
  background-image: url('../assets/images/owf-weed-image.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 380px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: '#212121';
}

.header-background {
	background-image: url('../assets/images/bg@2x.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 380px;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: '#212121';
}

.business-full-width-cta-container {
  background-image: url('../assets/images/owf-weed-image.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 380px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: '#212121';
}

.slider-cta-container {
  color: '#212121';
}
.box_header_overly {
  height: 700px;
  width: 100%;
  background-color: #17181d;
  overflow: hidden;
  position: absolute;
}

.featured-cta-container {
  display: flex;
  flex-direction: row;
  color: '#212121';
}

.join-cta-container {
  min-height: 280px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
  background-color: #00c853;
}

.cta-container {
  min-height: 280px;
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
}

.add-new-post-btn {
  background: rgb(0, 135, 31) 99%;
  background: linear-gradient(45deg, rgb(50, 191, 37) 0%, rgb(50, 191, 37) 99%);
  cursor: pointer;
}
.for-business-btn {
  background: rgb(0, 135, 31) 99%;
  background: linear-gradient(45deg, red, blue);
  cursor: pointer;
}
.landing-page-category-btn{
  background-color: transparent;
  /* background: rgb(0, 135, 31) 99%; */
  /* background: linear-gradient(45deg, red, blue); */
  padding: 14px 28px;
  cursor: pointer;
  color: #FFFFFF;
  border-color: #FFFFFF;
  border-radius: 16px;
  border-width: 2px;

}
.profile-avatar-container{
  margin-left: 12px;
  margin-right: 30px;
  margin-top: 13px;
}
.navbar-default {
  background-color: #0f1017 !important;
  z-index: 10;
}
.navbar-default-clear {
  background-color: transparent !important;
  position: absolute;
  width: 100%;
}
.navbar-center {
  position: absolute;
  width: 100%;
  text-align: center;
  margin: auto;
}
.loader {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.loader__image {
  width: 50px;
  height: 50px;
}
.flex-parent {
  display: flex;
  flex-direction: column;
}
.long-and-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.no-margin {
  margin: 0 !important;
  padding: 0 !important;
}
.input-landing-margin {
  padding: 0 !important;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar {
  margin-bottom: 0px !important;
}

.nav-profile-img-wrapper {
  height: 36px !important;
}
.mobile-nav-profile-img {
  border-radius: 50%;
  object-fit: cover;
  width: 62px;
  height: 62px;
  border: 2px solid rgba(187, 187, 187, 0.4);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.nav-profile-img {
  border-radius: 50%;
  object-fit: cover;
  width: 32px;
  height: 32px;
  border: 2px solid rgba(187, 187, 187, 0.4);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.nav-list-search {
  margin-top: 6px;
}
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: none !important;
  border-radius: 4px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.panel-default {
  border-color: none !important;
  position: absolute;
  width: 100px;
  padding-left: 10px;
  padding-top: 10px;
}
.app-title {
  font-size: 1.5em;
}
.app-intro {
  font-size: 40px;
  line-height: 1;
  color: #00e676;
}
.hero-type {
  margin-top: 10px;
}
.syntax_style {
  color: #b0bec5;
}
.dev-style {
  color: #242931;
}
.header_p {
  color: #78909c;
  font-size: 30px;
}
.sign-in-logo {
  margin-top: 5px;
  height: 55px;
}
.sign-up-logo {
  margin-top: 5px;
  height: 35px;
}
.app-header {
  margin-top: 60px;
}
.landing_img {
  text-align: center;
  width: 80%;
  margin: auto 0;
}
.logo {
  color: #212121;
  font-size: 20px;
}
.searchField {
  background-color: rgb(31, 35, 40);
  border-radius: 5px;
  border: 0;
  height: 35px;
  margin-bottom: 20px;
  padding: 5px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  color: rgb(91, 113, 124);
}
.tag-links {
  font-size: 16px;
  color: #ffffff;
}
.join-the-comm {
  color: #2b3137;
  background-color: #00e676;
  border: none;
}
.sub-links p {
  font-size: 12px;
  color: #495a63;
}
.profile-wrapper {
  padding-top: 16px;
}
.sidebar-card {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 16px 16px 0;
}
.sidebar-img-wrapper {
  width: 200px;
  height: 200px;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.sidebar-user-img {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border: 3px solid rgba(187, 187, 187, 0.4);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.sidebar-user-details-wrapper {
  width: 100%;
  /* display: flex; */
  /* flex-direction: row; */
  margin-bottom: 12px;
}
.sidebar-user-details-wrapper-dispensary {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}
.sidebar-user-name {
  font-size: 22px;
  font-weight: 600;
}
.sidebar-user-links {
  padding: 16px;
}
.sidebar-card-user-location {
  color: #78909c;
  font-size: 12px;
  margin-bottom: 0 !important;
}
.sidebar-user-bio {
  font-size: 16px;
}
.sidebar-user-stats {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
}
.create-new-list_text-area {
  background-color: rgb(236, 236, 236);
  width: 100%;
  border: 1px #e1e4e8 solid;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 12px;
}
.item-detail-wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  width: 100%;
}
.item-card {
  width: 100%;
  display: flex;
  flex: 1;
  padding: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: #fff;
  border-top: 2px #f8f8f8 solid;
  border-radius: 22px;
  align-items: center;
  box-shadow: 0 0px 4px 0 #bbbbbb50, 0 6px 20px 0 rgba(17, 27, 106, 0.064);
}
.item-card p {
  font-size: 10px;
}
.item-card-drag {
  width: 100%;
  display: flex;
  background-color: #fff;
}
.item-content {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}
.item-link-container {
  display: flex;
  flex-direction: row;
}
.item-link-wrapper {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0 !important;
}
.item-link {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1284ff;
}
.delete-link-icon {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.delete-icon {
  color: #bbbbbb;
  font-size: 16px;
}
.item-link-icon-style {
  color: #ffffff;
  font-size: 16px;
}
.item-link-url {
  color: #b0bec5;
}
.verified {
  background-image: url('../assets/images/verified@2x.png');
  height: 28px;
  width: 28px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-flex;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-flex;
}
.likes-container {
  width: 100%;
  flex: 1;
  display: flex;
  margin-right: 16;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.likes {
  margin-top: 12;
  width: 15px;
  height: 15px;
}
.profile-user-card-wrapper {
  height: 100%;
  width: 100%;
}
.profile-user-stats-wrapper {
  margin-top: 8px;
}
.avatar-card-style {
  margin-top: 16px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 0px 4px 0 #bbbbbb50, 0 6px 20px 0 rgba(17, 27, 106, 0.064);
}

.list-card-style {
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 22px;
  box-shadow: 0 0px 4px 0 #bbbbbb50, 0 3px 9px 0 rgba(17, 27, 106, 0.064);
}
.list-card-comment-style {
  margin-top: 16px;
  margin-bottom: 10px;
  border-radius: 22px;
}
.list-card {
  display: flex;
  padding: 12px;
}
.list-view-card {
  flex-direction: row;
}
.user-search-profile-avatar {
  border-radius: 50%;
  object-fit: cover;
  width: 24px;
  height: 24px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.list-profile-avatar {
  border-radius: 50%;
  object-fit: cover;
  width: 48px;
  height: 48px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.student-card-profile-avatar {
  border-radius: 50%;
  object-fit: cover;
  width: 36px;
  height: 36px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.profile-sidebar-avatar {
  border-radius: 16px;
  object-fit: cover;
  width: 100%;
  height: 80%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.list-profile-avatar-wrapper {
  display: flex;
  padding-right: 8px;
}
.student-profile-avatar-wrapper {
  align-items: center;
  padding-right: 8px;
}
.profile-avatar-wrapper {
  display: flex;
  align-items: center;
}
.list-profile-link-wrapper {
  flex-direction: column;
  /* padding:16px; */
}
.list-detail-wrapper {
  width: 100%;
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.list-user-name {
  font-size: 14px;
  font-weight: 600;
}
.list-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}
.list-level {
  font-size: 12px;
  font-weight: 700;
}
.timestamp {
  font-size: 12px;
  color: #999;
}
.list-items-wrapper {
  width: 100%;
  padding-bottom: 10px;
}
.card-style {
  background-color: #fff;
  border: 1px #e1e4e8 solid;
  border-radius: 22px;
  box-shadow: 0 1px 1px rgba(27, 31, 35, 0.1);
}

.edit-profile-btn {
  width: 100%;
  border-radius: 15px;
  height: 35px;
  color: #24292e;
  background-color: #eff3f6;
  background-image: linear-gradient(-180deg, #fafbfc, #eff3f6 90%);
}
.user-img-wrapper {
  width: 100%;
  height: 100%;
}
.user-img {
  border-radius: 50%;
  object-fit: cover;
  width: 200px;
  height: 200px;
  border: 2px solid #e4e4e4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.user-name {
  font-size: 20px;
  line-height: 1;
}
.user-location {
  color: #78909c;
  font-size: 15px;
  line-height: 1;
}
.user-descriiption {
  color: #242931;
  font-size: 15px;
  line-height: 1.1;
}
.user-keywords {
  color: #78909c;
  font-size: 12px;
  line-height: 1;
}
.user-btn {
  background: #00e676;
  border-radius: 5px;
  border: none;
  height: 30px;
  width: 40%;
  margin: 10px;
}
/*////////////////////////*/
/*     Add Item Form      */
/*////////////////////////*/
.form-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.add-item-form {
  border-top: #e1e4e8 1px solid;
  padding-top: 10px;
  width: 100%;
}

.add-item-input {
  padding: 10px 10px 10px 5px;
  /* width:90%; */
  height: 35px;
  border: none;
  background-color: #f7f7f7;
}
.add-item-input input:focus {
  outline: none;
}
.add-item-btn {
  height: 100%;
  background: rgb(230, 230, 230);
}
.add-link-btn {
  height: 100%;
  background: rgb(230, 230, 230);
}
.add-link-text-field {
  width: 100%;
  background-color: #f7f7f7;
  margin-bottom: 10px;
}
.follow-btn {
  flex: 1;
  align-self: stretch;
  background-color: #efefef;
  height: 28px;
  width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0px 4px 0 #bbbbbb50, 0 5px 0px 0 rgba(17, 27, 106, 0.064);
}
.follow-button-text {
  flex: 1;
  text-align: center;
  color: #212121;
  font-weight: bold;
  padding-top: 4px;
}
.unfollow-btn {
  flex-direction: row;
  align-self: stretch;
  background-color: #efefef;
  height: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid #dbdbdb;
  cursor: pointer;
}
.unfollow-button-text {
  flex: 1;
  text-align: center;
  color: #262626;
  font-weight: bold;
  padding-top: 4px;
}
.comment-detail-wrapper {
  width: 100%;
  padding-right: 10px;
  flex-wrap: wrap;
  margin-left: 8px;
}
.comment-profile-avatar {
  object-fit: cover;
  width: 32px;
  height: 32px;
  clip-path: circle(16px at center);
}
.comment-display-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 2px;
}
.comment-body-text-container {
  /* font-size: 14px; */
}
.comment-text {
  font-size: 14px;
  margin-bottom: 2px;
}
.add-comment-container {
  display: flex;
  flex-direction: row;
  height: 70px;
  margin-bottom: 30px;
}
.add-comment-profile-avatar {
  object-fit: cover;
  width: 32px;
  height: 32px;
  clip-path: circle(16px at center);
  margin-left: 16px;
  margin-top: 20px;
}
.add-comment-input-container-style {
  width: 100%;
}
.add-comment-input-textfield {
  background-color: #ffffff;
  height: 40px;
  width: 100%;
  border: none;
  margin-top: 14px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
}
.add-comment-button-container {
  margin-right: 16px;
  margin-top: 25px;
  padding-left: 10px;
  height: 30px;
  border-left: 1px solid #e8eaf6;
}
.add-comment-div-style {
  font-size: 14px;
  color: #536dfe !important;
}
.add-comment-button-style :visited {
  color: #536dfe;
}
.remove-comment-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-comment-btn button {
  border: none;
}
.remove-btn-icon {
  color: #e0e0e0;
}
.remove-btn-none {
  display: none;
}
.tab-bar-wrapper {
  border-top: 1px solid #ecebec;
  background-color: #ffffff;
  border: 0;
  bottom: 0;
  padding-bottom: 22px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  left: 0;
  position: fixed;
  right: 0;
  top: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
}
.tab-bar-container {
  border-top: 1px solid #ecebec;
  padding-top: 10px;
  min-height: 44px;
  height: 100%;
  background-color: #fff;
}
.tab-bar-inner-wrapper {
  background-color: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}
.tab-bar-button {
  background-color: #fff;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ux {
  background-color: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tab-bar-icon {
  width: 24px;
  height: 24px;
}

.account-img-wrapper {
  width: 48px;
  height: 48px;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.account-user-img {
  border-radius: 50%;
  object-fit: cover;
  width: 48px;
  height: 48px;
  border: 2px solid #e4e4e4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mobile-profile-avatar {
  border-radius: 50%;
  object-fit: cover;
  width: 70px;
  height: 70px;
  border: 2px solid #e4e4e4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mobile-detail-wrapper {
  width: 100%;
  margin-left: 20px;
}
.profile-menu-bar-container {
  padding-top: 10px;
  margin-bottom: 16px;
  min-height: 44px;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0px 4px rgba(187, 187, 187, 0.4);
}
.profile-menu-bar-inner-wrapper {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}
.profile-menu-bar-button {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.profile-menu-bar-ux {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.profile-menu-bar-button:last-child {
  border-left: 1px #e1e4e8 solid;
}

.profile-menu-bar-icon {
  width: 20px;
  height: 20px;
}
.account-img-wrapper {
  width: 48px;
  height: 48px;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.account-user-img {
  border-radius: 50%;
  object-fit: cover;
  width: 48px;
  height: 48px;
  border: 2px solid #e4e4e4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mobile-profile-avatar {
  border-radius: 50%;
  object-fit: cover;
  width: 70px;
  height: 70px;
  border: 2px solid #e4e4e4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mobile-detail-wrapper {
  width: 100%;
  margin-left: 20px;
}
.profile-menu-bar-container {
  padding-top: 10px;
  margin-bottom: 16px;
  min-height: 44px;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0px 4px rgba(187, 187, 187, 0.4);
}
.profile-menu-bar-inner-wrapper {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}
.profile-menu-bar-button {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.profile-menu-bar-ux {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.profile-menu-bar-button:last-child {
  border-left: 1px #e1e4e8 solid;
}
.tag_icon_style {
  width: 30px;
  height: 30px;
}

.product_tag_container {
  border-color: #eeeeee;
  width: 100%;
  border-style: solid;
  margin-right: 8;
  border-radius: 8;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product_form_price_breakdown {
  border-color: #eeeeee;
  width: 100%;
  border-style: solid;
  margin: 8px;
  border-radius: 8px;
  align-items: center;
}
.product_form_price_breakdown_container {
  margin: 16px;
  border: 2px solid #cccccc;
}
.product_form_price_input {
  height: 45px;
  width: 100%;
  border-radius: 12px;
  padding-left: 8px;
}
.new-post-cat-container {
  background-color: #eeeeee;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 16px;
}
.new-post-cat-inner-wrapper {
  margin-bottom: 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.likes_share_comment_container {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  align-items: center;
  /* width: 100%; */
  height: 70px;
  margin-bottom: 16px;
  border-radius: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  box-shadow: 0 0px 4px 0 #bbbbbb50, 0 5px 0px 0 rgba(17, 27, 106, 0.064);
  /* box-shadow: 0 0px 4px 0 #bbbbbb50, 0 6px 20px 0 rgba(17, 27, 106, 0.064); */
}

.featured-title-text-container {
  width: 100%;
}
/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .header-h1{
      /* font-weight: 900; */
      font-size: 3.8rem;
      /* line-height: 1; */
      font-family: 'Poppins', sans-serif;
      /* text-transform: uppercase; */
      color: #212121;
      margin: 0 !important;
      /* margin-bottom: 12px !important; */
    }
    .list-title {
      font-size: 14px;
      font-weight: 900;
      line-height: 1;
    }
    .item-link-wrapper {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0 !important;
    }
    .item-content {
      font-size: 13px;
      font-weight: 600;
      line-height: 1;
    }
    .comment-text{
      line-height: 1.2;
    }
    .list-user-name {
      font-size: 12px;
    }
    .item-link {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1284FF;
    }
    .item-link-icon-style{
      color: #FFFFFF;
      font-size: 12px;
    }
    .list-profile-avatar {
      border-radius: 50%;
      object-fit: cover;
      width: 30px;
      height: 30px;
      box-sizing: border-box;
  }
  .footer-banner {
    color: #fff;
    font-weight: 900;
    position: fixed;
    z-index: 2;
    bottom: 0;
    height: 90px;
    width: 100%;
    margin: 0 auto;
    background: linear-gradient(45deg, red, #00f);
    padding-top: 8px;
  }
  .sidebar-user-details-wrapper {
    width: 100%;
    display: flex;
    /* flex-direction: row; */
    margin-bottom: 12px;
  }
  .sidebar-user-details-wrapper-dispensary {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  .featured-title-text-container {
    width: 100%;
    /* min-height: 200px; */
  }
}
.select-container {
  border: 1px solid #999;
}
.select-css {
  padding: 12px;
  border-radius: 6px;
  background-color: #efefef;
  font-size: 12px;
  font-weight: 900;
}
.select-css-star-rating {
  padding: 12px;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 900;
  margin-right: 16px;
}

@media screen and (min-width: 768px) {
  .list-card-height {
    height: 600px;
  }
  .product-card-height {
    height: 600px;
  }
}
